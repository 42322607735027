import React, { useEffect, useRef, useState } from 'react'

const ShowReelVideo = ({ button }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const player = useRef()
  useEffect(() => {
    if (player && player.current) {
      const handleFullScreenChange = () => {
        if (!isPlaying) {
          setIsPlaying(true)
        } else {
          setIsPlaying(false)
          player.current.pause()
          player.current.currentTime = 0
        }
      }
      ;['', 'webkit', 'moz', 'ms', 'onms'].forEach(prefix =>
        player.current.addEventListener(
          `${prefix}fullscreenchange`,
          handleFullScreenChange
        )
      )
      document.addEventListener('MSFullscreenChange', handleFullScreenChange)

      return () => {
        ;['', 'webkit', 'moz', 'ms', 'onms'].forEach(prefix =>
          player.current.removeEventListener(
            `${prefix}fullscreenchange`,
            handleFullScreenChange
          )
        )
        document.removeEventListener(
          'MSFullscreenChange',
          handleFullScreenChange
        )
      }
    }
  }, [player.current, setIsPlaying, isPlaying])

  const play = () => {
    const div = player.current
    if (!div) return
    if (div.requestFullscreen) div.requestFullscreen()
    else if (div.webkitRequestFullscreen) div.webkitRequestFullscreen()
    else if (div.webkitEnterFullscreen) div.webkitEnterFullscreen()
    else if (div.msRequestFullscreen) div.msRequestFullscreen()
    div.play()
  }
  return (
    <>
      <video
        controls
        ref={player}
        style={{ display: isPlaying ? 'block' : 'none' }}
      >
        <source
          src="https://uxsd.s3.eu-west-1.amazonaws.com/showreel/showreel.mp4"
          type="video/mp4"
        />
        <source
          src="https://uxsd.s3.eu-west-1.amazonaws.com/showreel/showreel.ogg"
          type="video/ogg"
        />
        <source
          src="https://uxsd.s3.eu-west-1.amazonaws.com/showreel/showreel.webm"
          type="video/webm"
        />
      </video>
      {button ? button(play) : null}
    </>
  )
}

export default ShowReelVideo
