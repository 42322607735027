import React, { useContext } from 'react'
import { Tooltip } from 'react-tippy'

import DefaultLayout from '../layouts/defaultLayout/defaultLayout'
import Breadcrumbs from '../components/breadcrumbs/breadcrumbs.js'
import Button from '../components/button/button.js'
import ShowreelVideo from '../components/showreelVideo/showreelVideo.js'
import { GeoContext } from '../state/geo'
import './about.sass'

import aboutShowreel from '../assets/images/about_showreel.png'
import InfoBubbleIcon from '../assets/images/iconInfoBubble.inline.svg'
import paulImg from '../assets/images/about_paul2x.png'
import PlayIcon from '../assets/images/play.inline.svg'

const mockBreadcrumbs = [
  { title: 'UX Strategy Kit', href: '/' },
  { title: 'About Us', href: '#' },
]

export default function About(): JSX.Element {
  const { brand, url } = useContext(GeoContext)

  return (
    <DefaultLayout
      hasBanner={false}
      className="about"
      seo={{ title: 'About us' }}
    >
      <div className="about__container">
        <div className="about__breadcrumbs">
          <Breadcrumbs
            className="breadcrumbs--bright"
            breadcrumbs={mockBreadcrumbs}
          />
        </div>
        <section className="about__section">
          <div className="about__content about__content--blue">
            <h2 className="h2 h--decor about__header">
              Meet the team behind the UX Strategy Kit.
            </h2>
            <p>
              The User Experience Strategy &amp; Design team, part of the Chief
              Digital Organization at {brand}, is thrilled to see you here! To
              learn more about us, visit our team website.
            </p>
            <Button
              tag="a"
              href="https://uxsd.io"
              target="_blank"
              rel="noopener"
              className="about__wide-button"
            >
              www.uxsd.io
            </Button>
          </div>
          <div className="about__content about__content--image about__content--showreel">
            <img src={aboutShowreel} alt="" className="about__showreel" />
            <ShowreelVideo
              button={play => (
                <button onClick={play} className="about__playbutton">
                  <PlayIcon />
                </button>
              )}
            />
          </div>
        </section>
        <section className="about__section about__section--reverse">
          <div className="about__content about__content--image about__content--headofsection about-profile">
            <img
              src={paulImg}
              alt="Paul Svoboda"
              className="about-profile__photo"
            />
            <div className="about-profile__name">Paul Svoboda</div>
            <div className="about-profile__title">
              Head of User Experience Strategy &amp; Design at {brand}
            </div>
            <div className="about__button-tooltip-wrapper">
              <Button
                tag="a"
                href={`mailto:paul.svoboda@merckgroup.com`}
                className="about__wide-button"
              >
                Contact us
              </Button>
            </div>
            <div className="about__button-tooltip-wrapper">
              <Tooltip
                position="bottom"
                trigger="mouseenter"
                animation="fade"
                arrow={true}
                distance={24}
                html={
                  <div className="about__button-tooltip">
                    <InfoBubbleIcon />
                    <div>For {brand} employees only</div>
                  </div>
                }
              >
                <Button
                  tag="a"
                  href="https://eva.merckgroup.com/en-us/my-profile-network/about-me?accountname=DNEU\M252780"
                  target="_blank"
                  rel="noopener"
                  appearance="turq"
                  className="about__wide-button"
                >
                  Visit Paul’s EVA-Profile
                </Button>
              </Tooltip>
            </div>
          </div>
          <div className="about__content">
            <h2 className="h2 h--decor about__header">
              A GREAT Digital Product can BOOST your business!
            </h2>
            <p>
              We strongly believe in the power of great user experience,
              strategy, and design. After creating{' '}
              <a
                href={`https://liquid.${url}.design/`}
                rel="noreferrer noopener"
                target="_blank"
              >
                Liquid Oxygen
              </a>{' '}
              as a compendium of assets for developers and designers of digital
              products, the UX Strategy Kit, formerly known as the UX Toolkit,
              marks the next big step toward promoting this topic within {brand}
              .
            </p>
            <p>
              We hope the UX Strategy Kit provides you with an understanding of
              what user experience is and how your business can benefit from it.
            </p>
          </div>
        </section>
      </div>
    </DefaultLayout>
  )
}
